import Vue from 'vue';
import Vuex from 'vuex';
import * as fb from './firebase';
import update from 'immutability-helper';
import authenticatedRequest from './lib/authenticatedRequest';
import router from './router';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		activeUser: {},
		agreement: {},
		agreements: [],
		permissions: {},
		editedAgreement: null,
		events: [],
		featureflags: [],
		loading: true,
		saving: false,
		plans: [],
		subscriptions: null,
		user: {},
		userProfile: {},
		agreementUnsubscribe: null,
		userProfileUnsubscribe: null,
		unsubscribeActiveUser: null,
		childSupportTables: null,
		loginProvider: null,
		showPermissionDeniedPage:false,
		lawyerUid: null,
		currentRoleLevel:null,
		roleLevels:[],
		userRole: [],
		currentLoginPageNo:1,
		currentSignUpPageNo:1,
		clientUserAgreement:null,
		currentAgreementRoleLevelName:null,
		showAgreementTrialExpired: false,
		isLoggingOut: false,
	},
	mutations: {
		setLoginProvider(state, loginProvider){
			state.loginProvider = loginProvider;
			localStorage.setItem('loginProvider', state.loginProvider);
		},
		setActiveUser(state, activeUser) {
			state.activeUser = activeUser;
		},
		setLoading(state, loading) {
			state.loading = loading;
		},
		setPermissionDeniedPage(state, permissionDenied) {
			state.showPermissionDeniedPage = permissionDenied;
		},
		setSaving(state, saving) {
			state.saving = saving;
		},
		setUserProfile(state, userProfile) {
			state.userProfile = userProfile;
		},
		setAgreement(state, val) {
			state.agreement = val;
		},
		setPermissions(state, val) {
			state.permissions = val;
		},
		setUser(state, val) {
			state.user = val;
		},
		setAgreements(state, agreements) {
			state.agreements = agreements || [];
		},
		setPlans(state, plans) {
			state.plans = plans;
		},
		setSubscriptions(state, subscriptions) {
			state.subscriptions = subscriptions;
		},
		setEvents(state, events) {
			state.events = events;
		},
		setEditedAgreement(state, editedAgreement) {
			state.editedAgreement = editedAgreement;
		},
		setUnsubscribeUserProfile(state, unsubscribe) {
			state.userProfileUnsubscribe = unsubscribe;
		},
		setUnsubscribeAgreement(state, unsubscribe) {
			state.agreementUnsubscribe = unsubscribe;
		},
		setUnsubscribeActiveUser(state, unsubscribe) {
			state.unsubscribeActiveUser = unsubscribe;
		},
		setChildSupportTables(state, val) {
			state.childSupportTables = val;
		},
		setlawyerUid(state , id){ 
			state.lawyerUid = id
		},
		setCurrentRoleLevel(state , roleLevel){ 
			state.currentRoleLevel = roleLevel;
			if(roleLevel)
				localStorage.setItem('currentRoleLevel', JSON.stringify(roleLevel));
			else
				localStorage.removeItem('currentRoleLevel');
		},
		setAllRoleLevels(state , roleLevels){ 
			state.roleLevels = roleLevels
		},
		setUserRole(state, val){
			state.userRole = val;
		},
		setCurrentLoginPageNo(state, val){
			state.currentLoginPageNo = val;
		},
		setCurrentSignUpPageNo(state, val){
			state.currentSignUpPageNo = val;
		},
		setClientUserAgreement(state, val){
			if(val){
				localStorage.setItem('clientUserAgreement',val);
			} else {
				localStorage.removeItem('clientUserAgreement');
			}
			state.clientUserAgreement = val;
		},
		setCurrentRoleLevelName(state, val) {
			state.currentAgreementRoleLevelName = val;
		},
		setShowAgreementTrialExpired(state, val) {
			state.showAgreementTrialExpired = val;
		},
		setLoggingOut(state, value) {
			state.isLoggingOut = value;
		},
	},
	getters: {
		isAuthenticated: (state) =>
			state.user && state.user.uid ? true : false,
		loading: ({ loading }) => loading,
		agreementDetails: ( state ) => state.agreement,
		userRolePermissions: ( state ) => state.userRole,
		getCurrentRoleLevel(state) {
			return state.currentRoleLevel || (localStorage.getItem('currentRoleLevel') && JSON.parse(localStorage.getItem('currentRoleLevel'))) || null;
		},
		clientUserAgreement(state) {
			return state.clientUserAgreement || (localStorage.getItem('clientUserAgreement') && JSON.parse(localStorage.getItem('clientUserAgreement'))) || null;
		},
	},
	actions: {
		async login({ commit, dispatch }, user) {
			if (/@divii.ca\s*$/.test(user.email)) {
				fb.analytics.setUserProperties({ admin: true });
			}
			fb.analytics.setUserProperties({ userId: user.uid });
			const { claims } = await fb.auth.currentUser.getIdTokenResult();

			// save user data
			commit('setUser', { ...user, claims });
			commit('setPermissionDeniedPage', false);
			// fetch user profile and set in state
			dispatch('fetchUserProfile', user);
		},
		// async fetchLoginProvider({ state, commit }){
		// 	try{
		// 		const loginProvider = state.loginProvider;
				// commit('setLoginProvider', loginProvider);
		// 	} catch (error) {
		// 		console.error('Error fetch Login Provider', error);
		// 	}
		// },
		resetUser({ state, commit }) {
			commit('setUser', null);
			commit('setAgreements', []);
			commit('setUserProfile', []);
			commit('setLoading', false);
			commit('setSubscriptions', null);

			if (state.userProfileUnsubscribe) state.userProfileUnsubscribe();
			commit('setUnsubscribeUserProfile', null);

			// Reset the agreement as well.
			this.dispatch('resetAgreement');
		},
		resetAgreement({ commit, state }) {
			commit('setAgreement', {});
			commit('setPermissions', {});
			commit('setEvents', []);
			commit('setEditedAgreement', null);
			if (state.agreementUnsubscribe) state.agreementUnsubscribe();
			if (state.unsubscribeActiveUser) state.unsubscribeActiveUser;
			commit('setUnsubscribeAgreement', null);
		},
		async logout({ commit, dispatch }) {
				commit('setLoggingOut', true);
				await dispatch('clearConditionalStorageValues');
				
				await fb.auth.signOut();
				
				await dispatch('resetUser');
				
				localStorage.removeItem('lawyerUid');
				
		},
		clearConditionalStorageValues({ commit }){
			localStorage.removeItem('cliotoken');
			localStorage.removeItem('clioUser');
			localStorage.removeItem('loginProvider');
			localStorage.removeItem('currentDisplayMode');
			commit('setCurrentRoleLevel', null);
			localStorage.removeItem('redirectRouteParams');
			localStorage.removeItem('isClioFetch');
			localStorage.removeItem('loggedInEmailID');
			localStorage.removeItem('showGoogleLoginConfirmation');
			localStorage.removeItem('isAssetView');
			localStorage.removeItem('displayName');
			localStorage.removeItem('userLastName');
			localStorage.removeItem('phoneNumber');
			localStorage.removeItem('organizationName');
			localStorage.removeItem('locationOfPractice');
		},
		async fetchUserProfile({ commit, state, dispatch }, user) {
			// fetch user profile
			// console.log('user.uid ->',user.uid)
			commit('setLoading', true);
			console.log('user ->',user);
			if(user?.email)
				localStorage.setItem('loggedInEmailID',user.email);
			setRoleLevelForLoggedInUser(commit,state,user);
			const unsubscribe = fb.usersCollection.doc(user.uid).onSnapshot(
				async (userProfile) => {
					const { agreements, ...userProfileData } =
						userProfile.data() || {};
					// set user profile in state
					commit('setUserProfile', userProfileData);
					commit('setAgreements', agreements);

					this.checkAndRetrieveAgreementOnClientUserLogin;
					if (state.loading) {
						fb.remoteConfig
							.fetchAndActivate()
							.then(() => {
								commit('setLoading', false);
							})
							.catch((err) => {
								console.error(err);
								commit('setLoading', false);
							});
					}
				},
				(error) => {
					console.error(
						`Encountered error fetching user profile: ${error}`
					);
				}
			);
			commit('setUnsubscribeUserProfile', unsubscribe);
		},
		async fetchAgreement({ commit, state }, { id, realtime = false }) {
			commit('setShowAgreementTrialExpired',false)
			commit('setPermissionDeniedPage', false);
			const agreementRef = fb.agreementsCollection.doc(id);
			const permissionsRef = agreementRef
				.collection('users')
				.doc('permissions');
			console.log('permissionsRef==>',permissionsRef);
			if (realtime) {
				const unsubscribe = agreementRef.onSnapshot(
					(agreement) => {
						console.log('agreement.data()==>',agreement.data());
						commit('setAgreement', agreement.data());
					},
					(error) => {
						console.error(
							`Encountered error fetching agreement: ${error}`
						);
					}
				);
				commit('setUnsubscribeAgreement', unsubscribe);
			} else {
				try {
					const [agreement, permissions] = await Promise.all([
						agreementRef.get(),
						permissionsRef.get(),
					]);
					console.log('agreement==>',agreement);
					console.log('permissions==>',permissions);
					const uid = () =>
					Object.values(permissions.data()).filter(({ primaryLawyer }) => primaryLawyer === true);
					localStorage.setItem("lawyerUid", uid()[0].uid)
					let userEmail=localStorage.getItem('loggedInEmailID');

					const userRef = fb.usersCollection.doc(uid()[0].uid);
					const primaryLawyerProfile = await userRef.get();
					const { trialEnd, subscriptionStatus } = primaryLawyerProfile.data()
					const isPrimaryLawyerTrialExpired = subscriptionStatus ? false : trialEnd && !isAfter(parseISO(trialEnd), new Date());
					const userPermissionCheck = () =>
					Object.values(permissions.data()).filter(({ email }) => email === userEmail);
					console.log("userPermissionCheck:", userPermissionCheck()[0])
					if(isPrimaryLawyerTrialExpired && !state.userProfile.subscriptionStatus){
						commit('setShowAgreementTrialExpired',true)
					}
					else{
						commit('setShowAgreementTrialExpired',false)
					}

					if(!userPermissionCheck()[0]){
						// window.location.reload();
						commit('setPermissionDeniedPage', true);
					}else{
						commit('setPermissionDeniedPage', false);
					}
					commit('setlawyerUid', uid()[0].uid);

					// set active agreement & permissions in state - don't forget to reset on logout or changing an agrement.
					commit('setAgreement', agreement.data());
					commit('setPermissions', permissions.data());
					this.dispatch('watchActiveUser', id);
				} catch (error) {
					// if(localStorage.getItem('clientUserAgreement') && error.code =='permission-denied'){
					// 	commit('setPermissionDeniedPage', true);
					// }
					console.error(error.code);
					if(error.code =='permission-denied'){
						commit('setPermissionDeniedPage', true);
					}else{
						commit('setPermissionDeniedPage', false);
					}
				}
			}
		},
		async fetchPermissions({ commit, state }) {
			try {
				const permissions = await fb.agreementsCollection
					.doc(state.agreement.id)
					.collection('users')
					.doc('permissions')
					.get();
				commit('setPermissions', permissions.data());
			} catch (error) {
				console.error(error);
			}
		},
		async watchActiveUser({ commit }, agreementId) {
			// TODO handle closing the subscription
			const unsubscribe = fb.agreementsCollection
				.doc(agreementId)
				.collection('users')
				.doc('active')
				.onSnapshot(
					(activeUserSnap) => {
						const activeUser = activeUserSnap.data();
						if (activeUser && activeUser.user) {
							commit('setActiveUser', activeUser);
						} else {
							this.dispatch('setActiveUser', agreementId);
						}
					},
					(error) => {
						console.error(
							`Encountered error fetching agreement active user: ${error}`
						);
						if(error.code=='permission-denied'){
							commit('setPermissionDeniedPage', true);	
						}else{
							commit('setPermissionDeniedPage', false);
						}
					}
				);
			commit('setUnsubscribeActiveUser', unsubscribe);
		},
		async setActiveUser({ state }, agreementId) {
			try {
				if(state.user)
				await fb.agreementsCollection
					.doc(agreementId)
					.collection('users')
					.doc('active')
					.set({ user: [state.user.uid] });
			} catch (error) {
				console.error(error);
			}
		},
		async clearActiveUser(_, agreementId) {
			try {
				await fb.agreementsCollection
					.doc(agreementId)
					.collection('users')
					.doc('active')
					.set({ user: null });
			} catch (error) {
				console.error(error);
			}
		},
		async createAgreement({ state }, agreement) {
			try {
				if(state.user){
				const {
					data: { id },
				} = await authenticatedRequest('POST', '/agreements', {
					agreement,
					uid: state.user.uid,
				});

				// If we know at this point if this user has assistants or an Organization like thing could post to addAssistant here.
				// could make a bulk endpoint later or a background function to do this automatically

				// TODO error handle
				return { id };
			    }
			} catch (error) {
				console.error(error);
				return { error };
			}
		},
		async updateAgreement({ commit }, agreement) {
			if (!agreement.id) return;
			if (process.env.NODE_ENV === 'development')
				console.log('update agreement');

			commit('setSaving', true);
			const now = new Date().toISOString();

			await fb.agreementsCollection.doc(agreement.id).set({
				updatedAt: now,
				...agreement,
			});

			setTimeout(() => {
				commit('setSaving', false);
			}, 500);
		},
		updateAgreementProperty({ state }, { property, value }) {
			const updatedAgreement = update(state.agreement, {
				[property]: { $set: value },
			});

			this.dispatch('updateAgreement', updatedAgreement);
		},
		async deleteAgreement(_, agreementId) {
			const now = new Date().toISOString();
			try {
				await fb.agreementsCollection.doc(agreementId).set(
					{
						updatedAt: now,
						archived: now,
					},
					{ merge: true }
				);
			} catch (error) {
				console.error(
					`There was a problem archiving agreement ${agreementId}`,
					error
				);
			}
		},
		async deleteMultipleAgreements(_, agreementIds) {
			const now = new Date().toISOString();
			try {
			for(let i=0; i<agreementIds.length;i++){
				await fb.db.runTransaction(async (transaction) => {
				  const agreementRef = fb.agreementsCollection.doc(agreementIds[i]);
				   transaction.set(agreementRef, { updatedAt: now, archived: now }, { merge: true });
				});
			}
			} catch (error) {
			  console.error(`There was a problem archiving agreements: ${error}`);
			}
		},
		async getPlans({ commit }) {
			const querySnapshot = await fb.plansCollection
				.where('active', '==', true)
				.get();
			let plans = [];

			querySnapshot.forEach(async function (plan) {
				const priceSnap = await plan.ref
					.collection('prices')
					.where('active', '==', true)
					.get();
				let planData = { ...plan.data(), prices: [] };

				priceSnap.docs.forEach((price) => {
					planData.prices = [
						...planData.prices,
						{ ...price.data(), id: price.id },
					];
				});

				plans = [...plans, planData];
				commit('setPlans', plans);
			});
		},
		async getSubscriptions({ commit }, user) {
			const snapshot = await fb.customersCollection
				.doc(user.uid)
				.collection('subscriptions')
				.where('status', 'in', ['active'])
				.get();
			if (snapshot.empty) {
				return;
			}
			let subscriptions = [];

			snapshot.docs.forEach((subscription) => {
				subscriptions = [
					...subscriptions,
					{ ...subscription.data(), id: subscription.id },
				];
			});
			commit('setSubscriptions', subscriptions);
		},
		async updateEvent({ state, dispatch }, event) {
			const events = state.events;
			const eventIndex = events.findIndex(({ id }) => id == event.id);
			const updateScript =
				eventIndex > -1
					? {
							[eventIndex]: { $set: event },
					  }
					: {
							$push: [event],
					  };

			const tempEvents = update(events, updateScript);
			dispatch('updateEvents', tempEvents);

			return;
		},
		async deleteEvent({ state, dispatch }, event) {
			let events = state.events;
			let eventIndex = 0;
			// const eventIndex = events.findIndex(({ id }) => id == event.id);
			const eventNo = events.filter(({ eventNo }) => eventNo == event.eventNo);
			for(let i=0; i<eventNo.length;i++){
				events = state.events;
				if(eventNo[i] && eventNo[i].id){
					eventIndex = events.findIndex(({ id }) => id == eventNo[i].id);
				}
				const updateScript = {
					$splice: [[eventIndex, 1]],
				};
				
				const tempEvents = update(events, updateScript);
				dispatch('updateEvents', tempEvents);
			}
		},
		async updateEvents({ state, commit }, events) {
			commit('setSaving', true);
			commit('setEvents', events);
			try {
				await fb.agreementsCollection
					.doc(state.agreement.id)
					.collection('planner')
					.doc('schedule')
					.set({
						events,
					});

				setTimeout(() => {
					commit('setSaving', false);
				}, 500);
			} catch (error) {
				console.error('Something went wrong saving events');
			}
			return events;
		},
		async fetchEvents({ state, commit }) {
			try {
				const eventsRef = await fb.agreementsCollection
					.doc(state.agreement.id)
					.collection('planner')
					.doc('schedule')
					.get();
				if (eventsRef.exists) {
					const events = eventsRef.data();
					commit(
						'setEvents',
						events.events.map(({ start, end, ...rest }) => ({
							start: start.seconds ? start.toDate() : start,
							end: end.seconds ? end.toDate() : end,
							...rest,
						}))
					);
				} else {
					commit('setEvents', []);
				}
			} catch (error) {
				console.error('Error fetching events', error);
			}
		},
		async fetchEditedAgreement({ commit }, { id }) {
			try {
				const editedAgreement = await fb.agreementsCollection
					.doc(id)
					.collection('versions')
					.doc('editing')
					.get();
				if (editedAgreement.exists) {
					const agreement = editedAgreement.data();
					commit('setEditedAgreement', agreement.html);
				} else {
					commit('setEditedAgreement', null);
				}
			} catch (error) {
				console.error('Error fetching edited agreement', error);
			}
		},
		async fetchChildSupportTables({ commit }) { //removed "state," from this method.  
			try {
				const tablesSnap = await fb.realtimeDB
					.ref()
					.child('childSupport')
					.get();
				const tables = tablesSnap.val();
				commit('setChildSupportTables', tables);
			} catch (error) {
				console.error('could not fetch child support tables', error);
			}
		},
		async updateEditedAgreement({ state, commit }, agreementHtml) {
			commit('setEditedAgreement', agreementHtml);
			commit('setSaving', true);

			try {
				await fb.agreementsCollection
					.doc(state.agreement.id)
					.collection('versions')
					.doc('editing')
					.set({
						html: agreementHtml,
					});

				setTimeout(() => {
					commit('setSaving', false);
				}, 500);
			} catch (error) {
				console.error('Error saving edited agreement', error);
			}
		},
		async fetchCurrentCustomerProfile({ state, commit }){
			try{
				const customer = state.user;
				if(customer && customer.uid) {
					let customerDetails = await fb.customersCollection.doc(customer.uid).get();
					if(customerDetails.data()){
						if(customerDetails.data()?.displayName != undefined && customerDetails.data()?.displayName !=null)
							customer.displayName = customerDetails.data().displayName;
						if(customerDetails.data()?.lastName != undefined && customerDetails.data()?.lastName !=null)
							customer.lastName = customerDetails.data().lastName;
						if(customerDetails.data()?.organizationName != undefined && customerDetails.data()?.organizationName !=null)
							customer.organizationName = customerDetails.data().organizationName;
						if(customerDetails.data()?.locationOfPractice != undefined && customerDetails.data()?.locationOfPractice !=null)
							customer.locationOfPractice = customerDetails.data().locationOfPractice;
						if(customerDetails.data()?.phoneNumber != undefined && customerDetails.data()?.phoneNumber !=null)
							customer.phoneNumber = customerDetails.data().phoneNumber;
						if(customerDetails.data()?.isSharedUser != undefined && customerDetails.data()?.isSharedUser !=null)
							customer.isSharedUser = customerDetails.data().isSharedUser;
					}
					
					commit('setUser', customer);
				}
			} catch (error) {
				console.error('Error fetch Current Customer Profile', error);
			}
		},
		async updateDisplayName(_, customer){
			// try {
				let custDetails = await fb.customersCollection.doc(customer.uid).get();
				if(custDetails?.exists){
					await fb.customersCollection.doc(customer.uid).set({
							displayName: customer.displayName,
					}, { merge: true });
				} else {
					await fb.customersCollection.doc(customer.uid).set({
						displayName: customer.displayName,
						email:customer.email
					});
				}
				// localStorage.setItem('displayName', customer.displayName);
				this.dispatch('fetchCurrentCustomerProfile');
			// } catch (error) {
			// 	console.error(error);
			// }
		},
		async updateLastName(_, customer){
			let custDetails = await fb.customersCollection.doc(customer.uid).get();
			if(custDetails?.exists){
				await fb.customersCollection.doc(customer.uid).set({
						lastName: customer.lastName,
				}, { merge: true });
			} else {
				await fb.customersCollection.doc(customer.uid).set({
					lastName: customer.lastName,
					email:customer.email
				});
			}
			// localStorage.setItem('userLastName', customer.lastName);
			this.dispatch('fetchCurrentCustomerProfile');
		},
		async updatePhoneNumber(_, customer){
			let custDetails = await fb.customersCollection.doc(customer.uid).get();
			if(custDetails?.exists){
				await fb.customersCollection.doc(customer.uid).set({
						phoneNumber: customer.phoneNumber,
				}, { merge: true });
			} else {
				await fb.customersCollection.doc(customer.uid).set({
					phoneNumber: customer.phoneNumber,
					email:customer.email
				});
			}
			// localStorage.setItem('phoneNumber', customer.phoneNumber);
			this.dispatch('fetchCurrentCustomerProfile');
		},
		async updateOrganizationName(_, customer){
				let custDetails = await fb.customersCollection.doc(customer.uid).get();
				if(custDetails?.exists){
					await fb.customersCollection.doc(customer.uid).set({
						organizationName: customer.organizationName,
					}, { merge: true });
				} else {
					await fb.customersCollection.doc(customer.uid).set({
						organizationName: customer.organizationName,
						email:customer.email
					});
				}
				// localStorage.setItem('organizationName', customer.organizationName);
				this.dispatch('fetchCurrentCustomerProfile');
		},
		async updateLocationOfPractice(_, customer){
			let custDetails = await fb.customersCollection.doc(customer.uid).get();
			if(custDetails?.exists && customer){
				await fb.customersCollection.doc(customer.uid).set({
					locationOfPractice: customer.locationOfPractice,
				}, { merge: true });
			} else {
				await fb.customersCollection.doc(customer.uid).set({
					locationOfPractice: customer.locationOfPractice,
					email:customer.email
				});
			}
			// localStorage.setItem('locationOfPractice', customer.locationOfPractice);
			this.dispatch('fetchCurrentCustomerProfile');
	},
		async updateParentalPlannerTemplate({ state }, parentalPlannerTemplate) {
			try {
				await fb.agreementsCollection
					.doc(state.agreement.id)
					.set({
						parentalPlannerTemplate : parentalPlannerTemplate,
					}, { merge: true });
			} catch (error) {
				console.error('Something went wrong updating parenting planner template');
			}
			return parentalPlannerTemplate;
		},
		async getAllRoleLevels({ state, commit }){
			try{
				// const customer = state.user;
				// if(customer && customer.uid) {
					let roleLevels = await fb.roleLevelCollection.get();
					// console.log('roleLevels-->',roleLevels)
					// console.log('roleLevels.data()-->',roleLevels.docs())
					let allRoles =[];
					roleLevels.docs.forEach((role) => {
						allRoles.push(role.data());
					});
					if(allRoles){
						commit('setAllRoleLevels', allRoles);
					}
				// }
			} catch (error) {
				console.error('Error fetch Current Customer Profile', error);
			}
		},
		async addUserRole({ state }, rolePermissions) {
			try {
				await fb.roleLevelCollection
					.add(rolePermissions);
			} catch (error) {
				console.error(error);
			}
		},
		async updateUserRole({ state }, rolePermission) {
			try {
				await fb.roleLevelCollection
					.doc(rolePermission.id)
					.set(rolePermission);
			} catch (error) {
				console.error('Something went wrong editing user role');
			}
			return rolePermission;
		},
		async getUserRole({commit}) {
			try {
					let roleSnapShot=[];
					let refIds = [];
					await fb.roleLevelCollection.get()
					.then((snapChat)=>{
						snapChat.forEach((doc)=>{
							refIds.push(doc.id);
							roleSnapShot.push(doc.data());
						})
					});
					roleSnapShot = roleSnapShot.map((obj, index) => {
						obj.id = refIds[index];
						return obj;
					  });
					commit('setUserRole', roleSnapShot);
			} catch (error) {
				console.error('Something went wrong in getting user role');
			}
		},
		async deleteUserRole(_, roleId) {
			const now = new Date().toISOString();
			try {
				await fb.roleLevelCollection.doc(roleId).delete();
			} catch (error) {
				console.error(
					`There was a problem deleting User Role ${roleId}`,
					error
				);
			}
		},
		setCurrentLoginPageAction({commit},pageNo){
			commit('setCurrentLoginPageNo',pageNo);
		},
		setCurrentSignUpPageAction({commit},pageNo){
			commit('setCurrentSignUpPageNo',pageNo);
		},
		setClientUserAgreementAction({commit},agreementId){
			commit('setClientUserAgreement',agreementId);
		}
		
	},
});

async function setRoleLevelForLoggedInUser(commit,state,user){
	let custDetails = await fb.customersCollection.doc(user.uid).get();
			// console.log('custDetails -===========>',custDetails.data());
			// console.log('roleLevels >>>>>>>',state.roleLevels);
			let roleDetails;
			if(state.roleLevels && state.roleLevels.length){
				if(custDetails && custDetails.data() && custDetails.data().roleLevel){
					roleDetails = state.roleLevels.find((role)=> role.roleName == custDetails.data().roleLevel);
				} else {
					// let roles = await fb.roleLevelCollection.where('roleName', '==', 'lawyer-level-1').get();
					// if(roles && roles.docs && roles.docs[0] && roles.docs[0].data()){
					// 	roleDetails = roles.docs[0];
					// }
					roleDetails = state.roleLevels.find((role)=> role.roleName == 'lawyer-level-1');
				}
				if(roleDetails){
					commit('setCurrentRoleLevel', roleDetails);
				}
			}
			// console.log('roleDetails -===========>',roleDetails);
}



export default store;
