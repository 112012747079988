import Router from 'vue-router';
import { auth, remoteConfig } from './firebase';
import store from './store';
import { EventBus } from '@/eventBus';

const Admin = () => import(/* webpackChunkName: "admin" */ './views/Admin.vue');
const EmailAction = () =>
	import(/* webpackChunkName: "action" */ './views/EmailAction.vue');
const Trial = () => import(/* webpackChunkName: "trial" */ './views/Trial.vue');
const Login = () => import(/* webpackChunkName: "login" */ './views/Login.vue');
const ForgotPassword = () => import(/* webpackChunkName: "forgotPassword" */ './views/ForgotPassword.vue');
const ResetPassword = () => import(/* webpackChunkName: "resetPassword" */ './views/ResetPasswordFlow.vue');
const Create = () =>
	import(/* webpackChunkName: "create" */ './views/Agreements/Create.vue');
const DownloadWrapper = () =>
	import(
		/* webpackChunkName: "DownloadWrapper" */ './views/DownloadWrapper.vue'
	);
const Agreement = () =>
	import(/* webpackChunkName: "agreement" */ './views/Agreement.vue');
const Agreements = () =>
	import(
		/* webpackChunkName: "agreementsHome" */ './views/Agreements/Home.vue'
	);
const Profile = () =>
	import(/* webpackChunkName: "profile" */ './views/Profile.vue');
const Subscription = () =>
	import(/* webpackChunkName: "subscription" */ './views/Subscription.vue');
const ChildSupportCalculations = () =>
	import(
		/* webpackChunkName: "childSupport" */ './views/ChildSupportCalculationsWrapper.vue'
	);

const ClioHome = () => import(/* webpackChunkName: "ClioHome" */ './views/ClioHome.vue');
const PostLogin = () => import('./views/PostLogin.vue');

const authentication = () => import(/* webpackChunkName: "ClioHome" */ './views/authentication.vue');
const UserRole = () =>
	import(/* webpackChunkName: "create" */ './views/UserRole/Roles.vue');

function checkAuthStatus() {
	return new Promise((resolve, reject) => {
		try {
			auth.onAuthStateChanged((user) => {
				resolve(user);
			});
		} catch {
			reject('auth status fail');
		}
	});
}

async function requireAdmin(to, from, next) {
	const { claims } = await auth.currentUser.getIdTokenResult();

	if (
		process.env.NODE_ENV !== 'production' ||
		remoteConfig.getValue('staging').asBoolean()
	) {
		next();
		return;
	}

	if (!auth.currentUser || !store.getters.loading || !claims.admin) {
		console.log('....')
		if (to.params && to.params.id) {
			next({
				name: 'agreement',
				params: { id: to.params.id },
			});
		} else {
			console.log('+++')
			next({
				name: 'postLogin',
			});
		}
	}
	next();
}

function requireAuth(to, from, next) {
	if (!auth.currentUser && !store.getters.loading) {
		next({
			name: 'login',
		});
	}
	next();
}

function clientCheck(to, from, next) {
	// if (localStorage.getItem("clientUserAgreement")) {
	// 	next({
	// 		name: 'login',
	// 	});
	// }
	next();
}

function isAuthed(to, from, next) {
	if (auth.currentUser) {
		next({
			name: 'home',
		});
	}
	next();
}

function beforeEnterUserRole(to, from, next) {
	// Allows access to users role page only for specified user emails
	const emails= ['test-divii@mailinator.com', 'ramya.r@aot-technologies.com', 'neetha.john@aot-technologies.com'];
	if(!emails.includes(auth?.currentUser?.email)) {
		EventBus.$emit(
			'show-toast', 
			{ title: 'Permission Denied!', message: 'You don\'t have permission for users role page', variant: 'danger' }
		);
		next({ name: 'agreementsHome' });
	}
	next();
}

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [

		{
			name: 'childSupport',
			path: '/child-support',
			component: ChildSupportCalculations,
			beforeEnter: clientCheck,
			meta: {
				requiresAuth: false,
				noRedirect: true,
			},
		},
		{
			name: 'home',
			path: '/',
			// redirect: localStorage.getItem('clientUserAgreement') ? {
			// 	name: 'clientUserAgreement',
			// 	params: { id: localStorage.getItem('clientUserAgreement') },
			// } : { name: 'agreementsHome' },
			redirect:{name :'agreementsHome'}
		},
		{
			name: 'trial',
			path: '/trial',
			component: Trial,
			beforeEnter: isAuthed,
			meta: {
				requiresAuth: false,
			},
			redirect: to => ({ name :'auth', query: { ...to.query, page: 1, auth:1 } }) 
		},
		{
			name: 'trialClientUser',
			path: '/trial/:id',
			component: Trial,
			beforeEnter: isAuthed,
			meta: {
				requiresAuth: false,
			},
			redirect: to => ({ name :'auth', query: { ...to.query, page: 1, auth:1 } })
		},
		{
			name: 'login',
			path: '/login',
			component: Login,
			beforeEnter: isAuthed,
			meta: {
				requiresAuth: false,
			},
			redirect: to => ({ name :'auth', query: { ...to.query, page: 1, auth:0 } })
		},
		{
			name: 'loginClientUser',
			path: '/login/:id',
			component: Login,
			beforeEnter: isAuthed,
			meta: {
				requiresAuth: false,
			},
			redirect: to => ({ name :'auth', query: { ...to.query, page: 1, auth:0 } })
		},
		{
			name: 'action',
			path: '/action',
			props: true,
			meta: {
				requiresAuth: false,
			},
			component: EmailAction,
		},
		{
			name: 'createAgreement',
			path: '/agreement/create',
			// beforeEnter: requireAuth,
			beforeEnter(to, from, next) {
				requireAuth
				// if (localStorage.getItem("clientUserAgreement")) {
				// 	next({
				// 		name: 'login',
				// 	});
				// }
				next();
			},
			component: Create,
			meta: {
				requiresAuth: true,
			},
		},
		{
			name: 'preview',
			path: '/agreement/:id/preview',
			// beforeEnter: requireAuth,
			beforeEnter(to, from, next) {
				requireAuth
				// if (localStorage.getItem("clientUserAgreement")) {
				// 	next({
				// 		name: 'login',
				// 	});
				// }
				next();
			},
			component: DownloadWrapper,
			props: true,
			meta: {
				requiresAuth: true,
			},
		},
		{
			name: 'admin',
			path: '/agreement/:id/admin',
			beforeEnter: requireAdmin,
			component: Admin,
			meta: {
				requiresAuth: true,
			},
		},
		{
			name: 'agreement',
			path: '/agreement/:id',
			// beforeEnter: requireAuth,
			beforeEnter(to, from, next) {
				requireAuth
				// if (localStorage.getItem("clientUserAgreement")) {
				// 	next({
				// 		name: 'login',
				// 	});
				// }
				next();
			},
			component: Agreement,
			props: true,
			meta: {
				requiresAuth: true,
			},
		},
		{
			name: 'clientUserAgreement',
			path: '/clientUserAgreement/:id',
			beforeEnter: requireAuth,
			component: Agreement,
			props: true,
			meta: {
				requiresAuth: true,
			},
		},
		{
			name: 'agreementsHome',
			path: '/agreements/',
			beforeEnter: requireAuth,
			component: Agreements,
			// beforeEnter(to, from, next) {
			// 	requireAuth
			// 	if (localStorage.getItem("clientUserAgreement")) {
			// 		next({
			// 			name: 'login',
			// 		});
			// 	}
			// 	next();
			// },
			meta: {
				requiresAuth: true,
			},
		},
		{
			name: 'profile',
			path: '/profile/',
			// beforeEnter: requireAuth,
			beforeEnter(to, from, next) {
				requireAuth
				// if (localStorage.getItem("clientUserAgreement")) {
				// 	next({
				// 		name: 'login',
				// 	});
				// }
				next();
			},
			component: Profile,
			meta: {
				requiresAuth: true,
			},
		},
		{
			name: 'subscription',
			path: '/subscription/',
			// beforeEnter: requireAuth,
			beforeEnter(to, from, next) {
				requireAuth
				// if (localStorage.getItem("clientUserAgreement")) {
				// 	next({
				// 		name: 'login',
				// 	});
				// }
				next();
			},
			component: Subscription,
			meta: {
				requiresAuth: true,
			},
		},
		{
			name: 'forgotpassword',
			path: '/forgotpassword',
			component: ForgotPassword,
			// beforeEnter(to, from, next) {
			//   const user = firebase.auth().currentUser;
			//   if (user) {
			// 	next({ name: "dashboard" });
			//   } else {
			// 	next();
			//   }
			// },
		  },
		  {
			name: 'resetpassword',
			path: '/resetpassword',
			component: ResetPassword,
			meta: {
				requiresAuth: true,
			},
		  },
		  {
			name: 'identity_callback',
			path: '/identity_callback',
			redirect: { name: 'clioHome' },
		  },
		  {
			name: 'callback',
			path: '/callback',
			redirect: { name: 'clioHome' },
		  },
		  {
			name: 'clioHome',
			path: '/clioHome',
			component: ClioHome,
		  },
		  {
			name: 'auth',
			path: '/auth',
			component: authentication,
			beforeEnter: isAuthed,
			props: { userType: 'lawyer' },
			meta: {
				requiresAuth: false,
				noRedirect: true,
			},
		  },
		  {
			name: 'coupleAuth',
			path: '/couple/auth',
			component: authentication,
			beforeEnter: isAuthed,
			props: { userType: 'party' },
			meta: {
			  requiresAuth: false,
			  noRedirect: true,
			},
		  },
		  {
			name: 'postLogin',
			path: '/postLogin',
			component: PostLogin,
		  },
		  {
			name: 'userrole',
			path: '/userrole',
			component: UserRole,
			beforeEnter: beforeEnterUserRole,
			meta: {
				requiresAuth: true,
			},
		  },
		{
			path: '/form-components',
			component: () =>
				import(
					/* webpackChunkName: "formComponents" */ './views/FormComponents.vue'
				),
		},
	],
});

// navigation guard to check for logged in users
router.beforeEach(async (to, from, next) => {
	if (store.state.isLoggingOut) {
        return next();
    }
	await checkAuthStatus();
	// console.log('*****')
	if (
		to.matched.some((record) => record.meta.requiresAuth) &&
		!auth.currentUser &&
		!store.getters.loading
	) {
		next({ path: '/auth', replace: true });
	} else if (
		to.matched.some((record) => !record.meta.requiresAuth) &&
		to.matched.some((record) => !record.meta.noRedirect) &&
		auth.currentUser
	) {
		// next({ path: '/' });
		// console.log('cccccccc')
		// if(to.name != 'postLogin')
		// return next({ path: '/postLogin' });
		if(to.matched[0].name == 'clioHome')
		next();
		else
		next({ path: '/' });
	}
	// console.log('to.name============>',to.name)

	// if(to.name == 'postLogin'){
	// 	next();
	// }
	// if(to.name == 'trialClientUser' && to.params && to.params.id){
	// 	// localStorage.setItem('clientUserAgreement', to.params.id);
	// 	console.log('trialClientUser')
	// 	next({ path: '/trial', replace: true });
	// }
	// if(to.name == 'loginClientUser' && to.params && to.params.id){
	// 	localStorage.setItem('clientUserAgreement', to.params.id);
	// 	console.log('loginClientUser')
	// 	next({ path: '/login', replace: true });
	// }
	// if(to.name == 'agreementsHome'){
			// if(localStorage.getItem('clientUserAgreement')) {
			// 	sessionStorage.setItem('activeTab','builder')
			// 	sessionStorage.setItem('activeIndex',0)
			// 	next({
			// 		name: 'clientUserAgreement',
			// 		params: { id: localStorage.getItem('clientUserAgreement') }
			// 	});
			// }
	// }
	// if(to.name == 'clientUserAgreement'){
	// 	sessionStorage.setItem('activeTab','builder')
	// 	sessionStorage.setItem('activeIndex',0)
	// }
	if(from?.name == 'agreement'){
		sessionStorage.setItem('activeIndex',0);
		sessionStorage.setItem('activeTab','builder');
	}
	next();
});

export default router;
