import { mapState } from 'vuex';
import * as fb from '../firebase';

export default {
	computed: {
		...mapState(['user']),
		async checkAndRetrieveAgreementOnClientUserLogin() {
					let agrID = this.getClientOrPreviouslyLoggedId; //'v1OyzHUuow7PRFm5QFpc';
					if(agrID){
						console.log('before perm->');
						let perm = await fb.agreementsCollection
											.doc(agrID)
											.collection("users")
											.doc("permissions")
											.get();
						console.log('perm->',JSON.stringify(perm.data()));
						let permissionData = perm.data();
						let permissionObjKeys = Object.keys(permissionData);
						if(permissionData && permissionObjKeys && permissionObjKeys.length){
							console.log('1');
							for(let i=0; i<permissionObjKeys.length ; i++){
								console.log('2',this.user.email,permissionData[permissionObjKeys[i]].email);
								if(this.user && this.user.email && permissionData[permissionObjKeys[i]].email && this.user.email == permissionData[permissionObjKeys[i]].email){
									console.log('3');
									if(permissionData[permissionObjKeys[i]].role){
										console.log('4');
										console.log('agrID->',agrID);
										this.$store.dispatch('fetchAgreement', {
											id: agrID
										})
									}
								}
							}
						} else {
                            console.log('checkAndRetrieveAgreementOnClientUserLogin else');
                        }
					}
		},

		getClientOrPreviouslyLoggedId(){
			// const clientUserAgrId = localStorage.getItem('clientUserAgreement') ? localStorage.getItem('clientUserAgreement') : localStorage.getItem('previouslyLoggedClientUserAgreement')
			return localStorage.getItem('clientUserAgreement');
		},
		getPreviouslyLoggedClientUserAgreement(){
			return localStorage.getItem('previouslyLoggedClientUserAgreement');
		},
		getclientUserAgreement(){
			return localStorage.getItem('clientUserAgreement');
		},
	},
	// methods:{
	// 	clientUserActionOnLogout(){
	// 		const clientUserAgrId = localStorage.getItem('clientUserAgreement') ? localStorage.getItem('clientUserAgreement') : localStorage.getItem('previouslyLoggedClientUserAgreement')
	// 		console.log('clientUserAgr     -- Id==>',clientUserAgrId)
	// 		if(clientUserAgrId){
	// 			localStorage.setItem('previouslyLoggedClientUserAgreement', clientUserAgrId);
	// 			console.log('1111111')
	// 		} else {
	// 			localStorage.removeItem('previouslyLoggedClientUserAgreement');
	// 			console.log('222222222')
	// 		}
	// 		localStorage.removeItem('openUserLoginAs');
	// 		localStorage.removeItem('clientUserAgreement');
	// 		return;
	// 	},
	// }
};
